<template>
  <div class="container">
    <div class="top-btn">
      <div
        class="back-button"
        @click="onback"
        :style="{ 'padding-top': statusBarHeight }"
      >
        <i class="icon-leftarrow"></i>
        <span class="title">返回</span>
      </div>
    </div>
    <div class="text-body">
      <div>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 20pt;
              font-style: normal;
              font-weight: bold;
            "
            >iMeddy私隱聲明</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 12pt;
              font-style: normal;
            "
            >本</span
          ><span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 12pt;
              font-style: normal;
            "
            >iMeddy</span
          ><span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 12pt;
              font-style: normal;
            "
            >私隱聲明載列本公司</span
          ><span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 12pt;
              font-style: normal;
            "
            >騰創醫療服務有限公司</span
          ><span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 12pt;
              font-style: normal;
            "
            >（「</span
          ><span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 12pt;
              font-style: normal;
            "
            >騰創</span
          ><span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 12pt;
              font-style: normal;
            "
            >」）如何收集、使用、管理及保護本公司可能向閣下收集或與閣下相關的個人資料或資料（「</span
          ><span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 12pt;
              font-style: normal;
              font-weight: bold;
            "
            >資料</span
          ><span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 12pt;
              font-style: normal;
            "
            >」），適用於其資料可能由本公司就</span
          ><span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 12pt;
              font-style: normal;
            "
          >
            iMeddy</span
          ><span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 12pt;
              font-style: normal;
            "
            >處理的所有個別人士。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >保護閣下的私隱</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >本公司致力根據規定的標準處理閣下的資料，其中包括（特別是及在適用的情況下）根據《個人資料（私隱）條例》（香港法例第486章）（「</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: bold;
            "
            >條例</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >」）的規定，保護閣下的私隱並確保閣下資料安全。
            在使用及提供閣下的資料以作本私隱聲明所載用途前，本公司或須按照法律取得閣下的書面同意，在此情況下，本公司須在取得有關書面同意後，方可按訂明的方式使用閣下的資料。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >閣下的資料</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >本公司或會收集、使用及持有閣下的各類不同資料。為經營本公司提供</span
          ><span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 12pt;
              font-style: normal;
            "
          >
            iMeddy</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >平台的業務，從而方便醫生為合資格用戶提供醫療和健康護理服務（包括核實閣下的身份、登記、啟用及管理閣下於本公司的帳戶，以及計費和收費（統稱為「</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: bold;
            "
            >服務</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >」）），並遵守適用政府部門、法院、執法機構或其他機構或監管機構頒佈的法律、規則、指引、規例及／或要求，本公司可能收集來自閣下或與閣下相關的資料或會包括但不限於：</span
          ><br /><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(a</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >)
            閣下身份證明文件（例如香港身份證及護照）上記錄的姓名、出生日期及其他詳情；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(b</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >) 聯絡資料，包括姓名、地址、電話號碼、手機號碼及電郵地址；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(c</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >)
            健康資料，包括醫療問題、自覺症狀、現時服用藥物、過敏及診斷結果；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(d</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >) 閣下與</span
          ><span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 12pt;
              font-style: normal;
            "
          >
            iMeddy</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >客戶服務人員之間的訊息交流；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(e</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >) 保險資料；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(f</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >) 信用卡資料；及</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(g</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >) </span
          ><span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 12pt;
              font-style: normal;
            "
            >iMeddy</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >及／或其各自承辦商、分包商、代理商、代表、業務合作夥伴或代表、服務供應商、健康護理供應商、健康護理專業人員（包括醫師／醫生、護士、藥劑師及其他醫護人員）可能不時要求，而且對於透過</span
          ><span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 12pt;
              font-style: normal;
            "
          >
            iMeddy</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
          >
          </span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >平台提供服務及／或閣下獲得健康護理供應商及／或健康護理專業人士提供的醫療諮詢及藥物處方服務（統稱為「</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: bold;
            "
            >醫療諮詢服務</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >」）而言屬必要的任何其他資料。 閣下提供的資料或會由</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >騰創</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >持有，並可在符合本私隱聲明載列的情況或用途時，由其僱員及下文訂明的獲授權第三方存取，或在事先向閣下發出通知，或取得閣下同意後（如需要）存取。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >本公司如何收集資料</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >本公司以多種方式收集資料，包括：</span
          ><br /><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(a) 由閣下直接提供，例如當閣下透過本公司網站、</span
          ><span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 12pt;
              font-style: normal;
            "
          >
            iMeddy</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
          >
            app、任何客戶服務熱線或</span
          ><span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 12pt;
              font-style: normal;
            "
          >
            iMeddy</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
          >
          </span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >聊天對話提交閣下資料時；或當閣下聯絡本公司提出查詢或要求時提供的資料；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(b)
            由第三方提供，如關聯實體、健康護理供應商、健康護理專業人士、業務合作夥伴或其他客戶，或閣下的代表，並經閣下提供適當同意（如需要）；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(c</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >) 閣下使用本公司網站或流動應用程式（見下文「</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: bold;
            "
            >私隱資料</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >」部分）；及／或</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(d</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >) 閣下參與本公司或代表本公司進行的調查或市場推廣活動。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >私隱資料</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >為更有效迎合閣下的需要和喜好，本公司的網站伺服器或會收集有關閣下的網站、設備或應用程式活動資料。本公司亦可能收集有關伺服器使用情況的匯總及匿名統計資料，以便配合本公司網站、流動和電視應用程式上的用戶行為。此類資料可能包括但不限於：</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(a</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >) 瀏覽器類型、版本及用戶代理；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(b</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >) 操作系統；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(c</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >) IP（互聯網協議）地址及／或域名；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(d</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >) 連接資料、頁面瀏覽統計數據及／或引薦網址；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(e</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >) 設備ID、位置及電話聯絡方式；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(f</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >) Cookies及／或瀏覽器、應用程式或網站伺服器日誌資料；及</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(g</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >) 設備及軟件特性及／或設定。</span
          ><br /><br /><span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 12pt;
              font-style: normal;
            "
            >iMeddy</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
          >
          </span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >網站或會在閣下的機器或設備上安裝cookies或類似追踪工具，以便本公司提供多項服務，包括度身訂造閣下的用戶體驗及／或在多個網頁及／或多次連線中保持登入狀態。該等資料可能包括但不限於相關登入及身份驗證資料，以及有關閣下在設備及本公司網站和流動應用程式上的活動和偏好設定資料。本公司網站可能預設為接受cookies。閣下可透過更改網頁瀏覽器的設定，以選擇拒絕接受或刪除過往的cookies；然而，屆時閣下或會無法正常使用本公司網站及／或應用程式的部分功能。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >本公司如何使用閣下的資料</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >本公司或會收集、保留及使用閣下的資料以作以下用途（如有需要，則會取得閣下同意）：</span
          ><br /><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >(a</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >) 核實閣下的身份；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >(b</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >) 處理閣下的</span
          ><span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
          >
            iMeddy</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
          >
          </span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >登記；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >(c</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >) 進行條例所界定的配對程序；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >(d</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >)
            核實閣下享受本公司所提供服務、遊戲及／或推廣或其他活動的資格；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >(e</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >) 提供服務及／或會員計劃；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >(f</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >) 向閣下提供獎勵、推廣優惠、最新資料、優惠及活動邀請；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >(g</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >) 向閣下推廣及推銷本公司的服務；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >(h</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >) 進行研究或分析，以便本公司改善及提高服務水平；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >(i</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >) 為業務營運及／或計劃目的而進行調查及營銷、推廣、行為評分；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >(j</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >)
            進行市場及產品分析，以便製作統計報告（當中載有與任何已識別或可識別個別人士無關的匯總資料）；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >(k</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >) 維護及發展本公司的業務系統及基建，包括測試及升級</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >該等系統</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >(l</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >) 維持、改善及發展本公司的產品及服務組合；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >(m</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >)
            遵守適用政府部門、法院、執法機構、監管或調查機構可能就向閣下提供的服務及／或會員計劃而規定的香港境內外適用法律（包括協助預防、偵查罪案或潛在犯罪活動）；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >(n</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >) 分發本公司、業務合作夥伴和交易對手方的出版物及研究材料；及</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >(o</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >)
            方便閣下獲得醫療諮詢服務，以及方便健康護理供應商及健康護理專業人士提供醫療諮詢服務。</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >本公司如何披露閣下的資料</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >為提供閣下要求的服務，本公司或會在適用法律及規例允許的範圍內，向</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >騰創</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >以外的香港境內外組織或其他人士（統稱為「</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: bold;
            "
            >組織</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >」）披露閣下的資料。閣下的資料僅會為向閣下提供本公司的服務而披露予該等組織。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >該等組織為本公司的業務及營運提供支援服務，有關業務及營運包括但不限於：</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(a) 醫療諮詢服務；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(b) 速遞、送貨及物流服務；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(c) 營銷、廣告及電話營銷服務；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(d) 計費服務；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(e) 收債服務；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(f) 資訊科技服務；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(g) 市場研究；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(h) 營銷、廣告及電話營銷服務；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(i) 客戶使用情況及行為分析；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(j) 流程管理；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(k) 調查；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(l) 網站使用情況分析；及</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(m) 雲儲存服務。</span
          ><br /><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >本公司採取必要措施，以確保該等組織受到有關保護閣下資料的適當保密及私隱義務所約束，並僅為進行其受聘目的之服務，而非自身或其他用途（包括直接促銷）使用閣下的資料。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >此外，本公司或會在以下情況披露閣下的資料：</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(a</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >) 應閣下要求，披露予閣下的獲授權代表及／或法律顧問；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(b</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >)
            為提供行政、付款、收款、業務、法律及／或營運支援服務而披露予：</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(i</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >)
            金融機構、消費卡或信用卡發行公司、信貸提供者、信貸資料庫、收款代理或擔保機構；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(ii</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >) 電訊網絡營運商；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(iii</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >) 本公司的聯營公司、海外辦事處、承讓人、受讓人及代表；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(iv</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >) 本公司的專業顧問，包括會計師、核數師、律師及保險商；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(c</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >) 就有關服務及適用於</span
          ><span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 12pt;
              font-style: normal;
            "
          >
            iMeddy</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
          >
          </span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >登記用戶的利益，披露予銀行、保險公司、保險經紀、核保師、計費代理及各業務合作夥伴；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(d</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >) 根據法律規定或授權，披露予政府及監管機構以及其他組織；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(e</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >)
            披露予管理本公司業務及公司策略的組織，包括參與轉讓或出售本公司全部或部分資產或業務（包括應收賬款及貿易應收款項）的組織，以及參與管理本公司企業風險及資金職能（例如證券化）的組織；</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(f</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >)
            披露予本公司營運或業務全部或任何部分相關成員的任何擬定或實際參與者、承讓人或受讓人；及／或</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >(g</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >) 披露予慈善機構或非牟利組織。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >直接促銷（如適用）</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >在取得閣下同意後，本公司擬根據適用法律、規則及規例，使用閣下的資料（閣下的姓名、聯絡資料、服務使用情況、訂購詳情、位置資料及其他客戶詳情資料）作直接促銷用途，包括向閣下發送有關服務及有關電視、電訊、互聯網串流（OTT）服務、內容服務、流動語音、短訊及數據通訊、國際直撥電話（IDD）／漫遊、互聯網連接、雲服務、電子／流動支付、娛樂、秘書服務、個人助理服務及資訊服務（例如天氣、財經及新聞資訊）、設備配件、流動應用程式及軟件、電腦周邊設備、配件及軟件（包括手提電腦、手機、流動設備及配件、鍵盤、保安裝置及流動應用程式）、獎賞、會員及優惠計劃、生活風格、社交活動、旅行、銀行、煙酒、體育、音樂、遊戲、交通、家用產品、食品及飲料、財務、保險、財富管理服務及產品、退休金、投資、經紀、財務顧問、貸款及信貸以及其他金融服務及產品、博彩、教育、保健及健康、美容產品及服務、時尚及配飾、電子產品、社交網絡、科技、電子商務、物流、零售、家居及裝飾、媒體及高端消費產品及服務等其他產品及／或服務的禮品、折扣、專屬待遇、優惠及推廣通知及／或最新資料。有關營銷可能透過多種方式進行（例如以信函、賬單附帶廣告／訊息、電郵、電子短訊、多媒體短訊、即時訊息、應用程式推送通知、針對性電視訊息、節目觀看設備上的廣播訊息、電話、社交媒體或在網站或其他媒介的廣告等方式）。</span
          ><br /><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >在使用閣下的資料作本私隱聲明所述的直接促銷用途前，倘若法律規定本公司須取得閣下的同意，在此情況下，本公司須在取得有關同意後，方可使用閣下的資料作任何直接促銷用途。</span
          ><br /><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >本公司將尊重各人不使用個人資料作直接促銷用途的要求。閣下可選擇拒絕從有關服務接收直接促銷材料及／或通訊。同時，閣下可透過向本公司私隱條例事務主任發出書面要求，並提供閣下的登記姓名、登記電話號碼或電郵地址（如適用），以恢復接收該等材料及／或通訊（倘若閣下曾選擇拒絕從有關服務接收該等材料及／或通訊）。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >轉移資料至香港境外</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >本公司偶爾需要及／或為審慎起見而將閣下的資料轉移至香港境外，例如，本公司可以為預防、偵查或調查罪案，或為儲存、處理及收集有關資料的其他目的而轉移資料。倘若本公司轉移閣下的資料至香港境外，本公司將按照條例的現行規定進行。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >本公司重視閣下資料的安全</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >本公司盡一切必要努力，確保所持有的任何資料儲存於安全可靠的地方，並僅可由獲授權僱員或本私隱聲明提述的其他組織存取。</span
          ><br /><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >當本公司將閣下的資料交予第三方組織處理時，本公司會確保該等組織採取適當的保安措施，以保閣下資料安全，並遵守有關資料保護的適用原則。與本公司共享閣下資料的部分人士或會於海外處理有關資料。閣下可與本公司聯絡，以更深入了解本公司在此情況下用作確保閣下資料獲得充分保障的保護措施。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >保留閣下的資料</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >本公司將根據內部政策保留閣下的資料。本公司的政策符合條例，並涵蓋以下原則：</span
          ><br /><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >(a)
            僅在為達成收集資料原定或直接相關用途的所需期間保留有關資料，除非保留有關資料亦為遵從任何適用法律、規例或合約義務；及</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >(b)
            根據上述標準及本公司內部程序，自本公司的電子、人手及其他檔案系統中清除有關資料。</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >閣下查閱及更正資料的權利</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >本公司採取一切合理預防措施，以確保所收集、使用及披露的資料為準確、完整及最新。然而，有關資料的準確性大致取決於閣下提供的資料。閣下有權要求查閱及更正閣下的資料，本公司建議閣下：</span
          ><br /><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >(a</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >) 如閣下的資料有任何錯誤，請告知本公司；及</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >(b) 告知本公司有關閣下資料的最新變動。</span
          ><br /><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >倘若閣下希望查閱或修改本公司所持有的任何閣下資料，可以透過「</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: bold;
            "
            >如何聯絡本公司</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >」部分載列的方式，與本公司聯絡。</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >閣下亦可登錄</span
          ><span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
          >
            iMeddy</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
          >
            app，以行使查閱及更正資料的權利，透過</span
          ><span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
          >
            iMeddy</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
          >
            app，閣下可檢視及更正本公司所持有的部分閣下資料。</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >閣下可拒絕與本公司分享資料，在此情況下，本公司或會無法為閣下提供部分服務。</span
          ><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Montserrat;
              font-size: 15pt;
              font-style: normal;
            "
            >如何聯絡本公司</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >有關本公司遵從條例項下義務的所有問題及查詢，以及任何查閱及更正閣下資料的要求，請</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >電郵至</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
          >
          </span
          ><span
            style="
              color: #333333;
              font-family: 'Times New Roman';
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
              text-decoration: none;
            "
            >enquiry@tc-doctor.com</span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
          >
          </span
          ><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >。</span
          ><br /><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
              font-weight: normal;
            "
            >如欲提出有關本公司處理閣下資料的問題，請與本公司聯絡，以便本公司嘗試解決閣下的問題。</span
          ><br /><br /><span
            style="
              color: #212529;
              font-family: Roboto;
              font-size: 12pt;
              font-style: normal;
            "
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Calibri; font-size: 12pt">&nbsp;</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
//保留期待頁面
import { mapMutations } from "vuex";
export default {
  components: {},
  data() {
    return {
      statusBarHeight: "0px",
    };
  },
  methods: {
    ...mapMutations({
      next_bill: "reservation/next_bill",
    }),
    //設置返回
    onback() {
      this.next_bill();
    },
  },
  created: function () {
    if (this.$store.state.statusBarHeight > 0) {
      this.statusBarHeight = this.$store.state.statusBarHeight - 25 + "px";
    }
  },
};
</script>

<style lang="scss" scoped>
.span-tx {
  font-size: 16px;
}
// 这里是禁止长按选中文字
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.back-span {
  margin-top: 10px;
  color: white;
  text-align: center;
  width: 88%;
  padding: 8px;
  border-radius: 8px;
  height: auto;
  background-color: #54cbb7;
  font-size: 17px;
}
.container {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  color: #828282;
  font-weight: 400;
  flex-direction: column;
  font-size: 19px;
  color: #000000;
}
.back-button {
  width: 100%;
  border-radius: 2px;
  height: auto;
  color: white;
  font-size: 26px;
}
.top-btn {
  height: auto;
  width: 100%;
  padding: 15px;
  background-color: #60c5a7;
  top: 0px;
  position: fixed;
}
.text-body {
  width: 100%;
  height: auto;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 90px;
  overflow: hidden;
}
</style>
